<template>
  <div
    class="page-header"
    :style="{backgroundImage: 'url('+background+')'}"
  >

    <div class="container">
      <div class="breadc-box no-line">
        <div class="row">
          <div class="col-md-12">
            <h2 class="page-title">{{$t(title)}}</h2>
            <ul id="breadcrumbs" class="breadcrumbs none-style">
              <li v-for="path in paths" :key="path">
                <router-link :to="path.url">{{$t(path.title)!=''?$t(path.title):path.title}}</router-link></li>
                <!-- <li class="active">{{$t(title)}}</li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SecondaryBanner",
  props: {
    'title': String,
    'paths': Array,
    'background':String
  }
};
</script>

<style scoped>
.page-header{
    background-repeat: no-repeat;
    background-position: center;
}
[lang="ar"] .page-header{
    /* background-position: right; */
}
</style>
