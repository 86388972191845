<template>
  <div class="slider" data-show="1" data-arrow="true">
    <div>
      <div class="slider-item">
        <img src="/images/slide3.jpg" alt="" class="" />
        <div class="container">
          <div class="row">
            <div class="col-md-9 float-r">
              <div class="slider-content">
                <h4>{{$t('W1')}}</h4>
                <h1>{{$t('s1')}}</h1>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="slider-item">
        <img src="/images/slide2.jpg" alt="" class="" />
        <div class="container">
          <div class="row">
            <div class="col-md-9 float-r">
              <div class="slider-content">
                <h4>{{$t('W1')}}</h4>
                <h1>{{$t('s2')}}</h1>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="slider-item">
        <img src="/images/slide1.jpg" alt="" class="" />
        <div class="container">
          <div class="row">
            <div class="col-md-9 float-r">
              <div class="slider-content">
                <h4>{{$t('W1')}}</h4>
                <h1>{{$t('s3')}} </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myBanner",
};
</script>

<style scoped>


</style>
