<template>
  <section class="bg-primary" style="background: url('images/bg-service.jpg') no-repeat;">
    <div class="container">
      <div class="row flex-row">
        <div class="col-md-8">
          <h4>{{$t('OUR SERVICES')}}</h4>
          <h2 class="no-margin-bottom">{{$t('What we do')}}</h2>
        </div>
        <div class="col-md-4 text-right align-self-end">
          <router-link class="industris-lineheight" :to='"/services"'
            >{{$t('View all Services')}} <i class="icon ion-md-add-circle-outline float-r"></i
          ></router-link>
        </div>
      </div>
      <div class="industris-space-50"></div>
      <div class="row">
        <ServiceBox v-for="service in services" :key="service.id" :title="service.title" :image="service.image" :id='service.id'/>       
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ServiceBox  from './ServiceBox.vue';

export default {
  name: "ServicesBrief",
  components: { ServiceBox }, 
  data() {
    return {
      // services:['ss','dd','ff']
    }
  },
  computed: {
    ...mapState({
        language: state => state.language.language,
        services: state => state.services.services,
    }),
  }
};
</script>

<style scoped></style>
