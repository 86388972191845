<template>
  <footer id="site-footer" class="site-footer bg-footer">
    <div class="main-footer">
      <div class="container-custom">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="widget-footer">
              <div id="media_image-1" class="widget widget_media_image">
                <a href="index.html"><img src="/images/logofooter.svg" class="logoDlshad1" alt="dlshad" /></a>
              </div>
              <div id="custom_html-1" class="widget_text widget widget_custom_html">
                <div class="textwidget custom-html-widget">
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end col-lg-3 -->

          <div class="col-md-3 col-sm-6">
            <div class="widget-footer">
              <div
                id="custom_html-2"
                class="widget_text widget widget_custom_html padding-left"
              >
                <div class="textwidget custom-html-widget">
                  <ul>       
                    <li v-for="service in services" :key="service.id">
                      <!-- <router-link :to="{ name: 'service', params: {id: service.id } }">{{service.title}}
                      </router-link> -->
                       <a :href="`/services/${service.id}`">{{service.title}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- end col-lg-3 -->

          <div class="col-md-3 col-sm-6">
            <div class="widget-footer">
              <div
                id="custom_html-3"
                class="widget_text widge widget-footer widget_custom_html padding-left"
              >
                <div class="textwidget custom-html-widget">
                  <ul>
                    <li><router-link :to='"/"'>{{$t('Home')}}</router-link></li>
                    <li><router-link :to='"/services"'>{{$t('Services')}}</router-link></li>
                    <li><router-link :to='"/contact"'>{{$t('Contact Us')}}</router-link></li>                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- end col-lg-3 -->

          <div class="col-md-3 col-sm-6">
            <div class="widget-footer">
              
              <!-- / Mailchimp for WordPress Plugin -->

              <div class="footer-social ot-socials bg-white">
               {{$t('follow us')}} 
                <a target="_blank" href="https://www.facebook.com/Dlshad-Group-106214338696907/" rel="noopener noreferrer"
                  ><i class="icon ion-logo-facebook"></i
                ></a>
                <a target="_blank" href="https://instagram.com/dlshadcompany8?utm_medium=copy_link" rel="noopener noreferrer"
                  ><i class="icon ion-logo-instagram"></i
                ></a>
                <a target="_blank" href="https://www.linkedin.com/in/dlshad-group-2a05b3235/" rel="noopener noreferrer"
                  ><i class="icon ion-logo-linkedin"></i
                ></a>
                <!-- <a target="_blank" href="#" rel="noopener noreferrer"
                  ><i class="icon ion-logo-twitter"></i
                ></a> -->
                <a target="_blank" href="https://www.youtube.com/channel/UCYI5t1m_fIgHEpVYZJmWKgg" rel="noopener noreferrer"
                  ><i class="icon ion-logo-youtube"></i
                ></a>
              </div>
            </div>
          </div>
          <!-- end col-lg-3 -->
        </div>
      </div>
    </div>
    <!-- .main-footer -->
    <div class="footer-bottom">
      <div class="container-custom">
        <div class="row">
          <div class="col-md-12">
            <ul class="topbar-left">
              <li><i class="icon ion-md-pin"></i>{{$t('Location1')}}</li>
              <li style="direction: ltr"><i class="icon ion-md-call"></i>+40790007100</li>
              <li><i class="icon ion-md-mail"></i>info@dlshad-group.com</li>
            </ul>
            <a id="back-to-top" href="#" class="btn btn-back-to-top pull-right"
              >Back to top<i class="icon ion-ios-arrow-dropup-circle"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <!-- .copyright-footer -->
  </footer>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: "MyFooter",
  computed: {
    ...mapState({
        language: state => state.language.language,
        services: state => state.services.services,
    }),
}
};
</script>

<style scoped></style>
