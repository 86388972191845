<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-12 nn">
                    <h4 class="text-primary">{{$t('CLIENTS')}}</h4>
                    <h2 class="font-s">{{$t('What our customers say')}}</h2>

                    <div class="testi-slider" data-show="2" data-arrow="true">

                        <div v-for="review in reviews" :key="review.id">
                            <div class="testi-box">
                                <div class="testi-content">
                                    <p>{{review.review}}</p>
                                </div>
                                <div class="testi-info">
                                    <img :src="storage__url + '/' +  review.user_image" alt="" class="circle-img">
                                    <h4>{{review.user_name}}<span>{{review.user_position}}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>
<script>
import { mapState } from 'vuex'
import { storage_url } from '../constants';

export default {
    name: "Reviews",
    data() {
        return {
            storage__url:storage_url
        }
    },
    computed: {
    ...mapState({
        language: state => state.language.language,
        reviews: state => state.reviews.reviews,
    }),
    },
};
</script>

<style scoped></style>