<template>
<!-- start preloader -->
    <div class="preloader">
        <div class="preloader-inner">
            <img src="/images/preloader.gif" alt>
        </div>
    </div>
<!-- end preloader -->
</template>
<script>
export default {
  name: "Loader",
};
</script>
<style scoped>

.preloader {
  width: 100%;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10001;
}

.preloader div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

</style>