<template>
  <section class="bg-contact-info">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 float-r">
          <h4 class="text-primary" >{{$t('CONTACT INFO')}}</h4>
          <div class="contact-info">
            <!-- <h2>Hotline : <span class="text-primary">+84 1900 8198</span></h2> -->
            <p><i class="icon ion-md-mail"></i>info@dlshad-group.com</p>
            <p><i class="icon ion-md-call"></i>00963/52/442882 </p>
            <p><i class="icon ion-md-phone-portrait"></i>+40790007100</p>
            <p><i class="icon ion-md-phone-portrait"></i>+963933007100</p>
            <p>
              <i class="icon ion-md-pin"></i>{{$t('Location')}}
            </p>
          </div>
          <div class="space-industris" style="height: 40px"></div>
          <hr />
        </div>
        <div class="col-md-12">
          
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ContactInfo",
};
</script>

<style scoped>
.text-primary{ 
  font-size:27px
  }
.contact-info p{
 font-size:20px;
 font-weight:600px
}  

</style>
