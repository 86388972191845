import { api_url } from '../constants';
export default {
    state: {
        reviews:[],
        reviewsLoaded:false,
    },
    mutations: {
        setReviews: (state, reviews) => {
            state.reviews = reviews;
            state.reviewsLoaded = true;
        },
    },
    getters: {
        reviews: state => state.reviews,
    },
    actions: {
        fetchReviews: async (ctx) => {
            if(!ctx.state.reviewsLoaded){
                let res = await axios.get(`${api_url}/reviews`).catch(()=> {});
                if(res){
                    let reviews = res.data;
                    ctx.commit('setReviews',reviews);
                }
            }
        },
    },
}
