<template>
    <section class="no-padding-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12 float-r">
                    <div class="home-about">
                        <h4 class="text-primary">{{$t('About')}}</h4>
                        <h2>{{$t('About1')}}</h2>
                        <p>{{$t('AboutContent')}}</p>
                    </div>
                    <div class="industris-space-sm"></div>
                </div>
                <div class="col-md-offset-1 col-md-5 col-sm-12 sm-center">
                    <div class="about-img-right">
                        <img src="/images/about.jpg" alt="">
                        <div class="about-img-small">
                            <div class="overlay"><img src="/images/about-circle.png" class="circle-img" alt=""></div>
                                <div class="about-content">
                                <h2 class="text-primary">24+</h2>
                                <p class="text-white">Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="industris-space-60 sm-hidden"></div>
            <div class="industris-space-20"></div>
            
            <div class="industris-space"></div>
        </div>
    </section>
</template>
<script>
export default {
  name: "About",
};
</script>
<style scoped>
.home-about p{
    font-size: 26px;
    line-height: 1.5;
}
</style>