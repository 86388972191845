<template>
  <div class="collapse searchbar" id="searchbar">
    <div class="search-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search for..." />
              <span class="input-group-btn">
                <button class="btn btn-primary" type="button">Go!</button>
              </span>
            </div>
            <!-- /input-group -->
          </div>
          <!-- /.col-lg-6 -->
        </div>
      </div>
    </div>
  </div>
  <header id="site-header" class="site-header mobile-header-blue header-style-1">
    <div id="header_topbar" class="header-topbar md-hidden sm-hidden clearfix">
      <div class="container-custom">
        <div class="row">
          <div class="col-md-9">
            <!-- Info on Topbar -->
            <ul class="topbar-left">
              <li><i class="icon ion-md-pin"></i>{{$t('Location1')}}</li>
              <li><i class="icon ion-md-call"></i>+40790007100</li>
              <li><i class="icon ion-md-mail"></i>info@dlshad-group.com</li>
            </ul>
          </div>
          <!-- Info on topbar close -->
          <div class="col-md-3">
            <ul class="topbar-right pull-right">
              <!-- <li class="toggle_search topbar-search">
                <a href="#"><i class="icon ion-md-search"></i></a>
              </li>
              <li class="topbar-login"><a href="#"> Login </a></li> -->
              <li class="topbar-languages">
              <select v-model="language" v-on:change="selectLanguage">
                <option value="en">English</option>
                <option value="ar">Arabic</option>
                <option value="ku">Kurdish</option>
             </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Top bar close -->
    <div class="main-header md-hidden sm-hidden">
      <div class="container-custom">
        <div class="row">
          <div class="col-md-3 float-ltr">
            <div class="logo-brand">
              <router-link :to='"/"'><img src="/images/logo.svg" class="logoDlshad1" alt="dlshad" /></router-link>
            </div>
          </div>
          <div class="col-md-9 float-r-flx">
            <div class="main-navigation">
              <ul id="primary-menu" class="menu">
                <li
                  class="menu-item menu-item-has-children current-menu-ancestor current-menu-parent"
                >
                  <router-link active-class="active" :to='"/"'>{{$t('Home')}}</router-link>
                </li>
                <li class="menu-item menu-item-has-children">
                  <router-link active-class="active" :to='"/services"'>{{$t('Services')}}</router-link>
                </li>
                <li class="menu-item menu-item-has-children">
                  <router-link active-class="active" :to='"/contact"'>{{$t('Contact Us')}}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main header start -->

    <!-- Mobile header start -->
    <div class="mobile-header">
      <div class="container-custom">
        <div class="row">
          <div class="col-xs-6">
            <div class="logo-brand-mobile">
              <router-link :to='"/"'><img src="/images/logo.svg" class="logoDlshad2" alt="dlshad" /></router-link>
            </div>
          </div>
          <div class="col-xs-6">
            <div id="mmenu_toggle">
              <button></button>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="mobile-nav">
              <ul id="primary-menu-mobile" class="mobile-menu">
                <li
                  class="menu-item menu-item-has-children current-menu-ancestor current-menu-parent"
                >
                  <router-link active-class="active" :to='"/"'>{{$t('Home')}}</router-link>
                </li>
                <li class="menu-item menu-item-has-children">
                  <router-link active-class="active" :to='"/services"'>{{$t('Services')}}</router-link>
                </li>
                <li class="menu-item menu-item-has-children">
                  <router-link active-class="active" :to='"/contact"'>{{$t('Contact Us')}}</router-link>
                </li>
                <select v-model="language" v-on:change="selectLanguage">
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                  <option value="ku">Kurdish</option>
               </select>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "myNavbar",
  data(){
    return {
      language: this.$store.getters.language
    };
  },
  methods: {
    async selectLanguage(){
      await this.$store.dispatch('setLanguage',this.language);
      location.reload();
    }
  },
};

</script>

<style scoped>
.main-navigation ul li > a.active{
  color: #ffd100;
}

select {
    width: 105px;
    height: auto;
    background-color: transparent;
    color:white;
    font-size: 13px;
    font-weight: 600;
    border:0;
    outline: 0;
    cursor: pointer;
}
select option{
    background-color: #03132b;
    font-weight: 600;
    border:0;
    outline: 0;
    color: white;
}
@media only screen and (max-width: 990px) {
  select {
    color: #272023;
    font-size: 20px;
    width: 115px;
    padding: 6px 0px;
    font-weight: 500;
}

}
</style>
