<template>
   <div class="col-md-4 col-sm-12">
        <div class="widget-area primary-sidebar">
            <div class="widget widget-nav-menu">
                <h4 class="widget-title">{{$t('Other Services')}}</h4>
                <div class="menu-services-menu-container">
                    <ul id="menu-services-menu" class="menu">
                        <li v-for="service in services" :key="service.id">
                            <a :href="`/services/${service.id}`">{{service.title}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="service?.brochure !=null" class="widget widget-text">
                <h4 class="widget-title">{{$t('Brochures')}}</h4>
                <div class="textwidget">
                    <p>{{$t('l1')}}</p>
                    <a :href="'http://dlshad.hashtagweb.online/storage/' + service?.brochure" target="_blank">{{$t('l2')}}<i class="icon ion-md-archive"></i></a>
                </div>   
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: "ServiceList",
    props: {
        'service': String,
    },
    computed: {
        ...mapState({
            language: state => state.language.language,
            services: state => state.services.services,
        }),
    }
};
</script>

<style scoped></style>