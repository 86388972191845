<template>
    <div class="col-md-4 col-sm-6">
        <div class="services-box services-box-img">
            <div class="services-overlay"></div>
            <div class="services-content">
                <h3><router-link :to="{ name: 'service', params: {id:id } }">{{title}}</router-link></h3>
                <router-link class="view-detail" :to="{ name: 'service', params: {id:id } }">{{$t('View details')}}
                    <i class="icon ion-md-add-circle-outline float-r"></i>
                </router-link>
            </div> 
            <img :src="storage__url + '/' +  image" alt="services image">
        </div>
    </div>
</template>
<script>
import { storage_url } from '../constants';

export default {
  name: "ServiceBox",
   props: {    
     'title': String,
     'image':String,
     'id':Number
    },
  data() {
      return {
          storage__url:storage_url
      }
  },
};
</script>

<style scoped></style>