<template>
  <SecondaryBanner :title='title' :paths='paths' :background='background'/>
  <ServiceDetails :service='service'/>
</template>

<script>
import SecondaryBanner from '../components/SecondaryBanner.vue';
import ServiceDetails from '../components/ServiceDetails.vue';
import { api_url, storage_url } from '../constants';
// import NotFound  from '../views/NotFound.vue';

export default {
    name: 'service',
    components: { ServiceDetails , SecondaryBanner },
    data() {
      return {
        service:null
      }
    },
    async mounted(){
      loadPage();
      await this.$store.dispatch('fetchServices');
      await this.updateService(this.$route.params.id);
      
    },
    methods:{
      async updateService(id){
        let res = await axios.get(`${api_url}/services/${id}`).catch(()=> {
          // this.$router.replace(NotFound);
        });
        if(res){
          this.service = res.data;
        }
      }
    },
    computed:{
      title(){
        return this.service?.title??'';
      },
      paths(){
        return [
            {title:'Home',url:'/'},
            {title:'Services',url:'/services'},
            {title:this.service?.title??'',url:'/services/'+this.service?.id??''},
        ];;
      },
      background(){
        return storage_url + '/' + (this.service?.cover_image??'');
      },
    }

}
</script>

<style>

</style>
