import { createStore } from 'vuex'
import language from './language'
import services from './services'
import reviews from './reviews'

export const store = createStore({
    modules: {
        language,
        services,
        reviews
    }
})