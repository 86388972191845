<template>
  <SecondaryBanner :title='title' :paths='paths' :background='background'/>
  <MyServices/>
</template>

<script>
import SecondaryBanner from '../components/SecondaryBanner.vue';
import MyServices from '../components/Services.vue';

export default {
    name: 'Services',
    components: { SecondaryBanner , MyServices },
    async mounted(){
      await this.$store.dispatch('fetchServices');
      loadPage();
    },
    data() {
      return {
        title:"Services",
        paths:[
            {title:'Home',url:'/'},
            {title:'Services',url:'/services'}
        ],
        background:"/images/services.jpg"
      };
},
}
</script>

<style>

</style>
