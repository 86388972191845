<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3>{{$t('View map:')}}</h3>
                    <div class="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.239796755725!2d41.22678128470292!3d37.051768379897545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf5f1473688556243!2zMzfCsDAzJzA2LjQiTiA0McKwMTMnMjguNSJF!5e0!3m2!1sar!2s!4v1646825146875!5m2!1sar!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "MyMap",
};
</script>

<style scoped></style>