import { api_url } from '../constants';

export default {
    state: {
        services:[],
        servicesLoaded:false,
    },
    mutations: {
        setServices: (state, services) => {
            state.services = services;
            state.servicesLoaded = true;
        },
    },
    getters: {
        services: state => state.services,
    },
    actions: {
        fetchServices: async (ctx) => {
            if(!ctx.state.servicesLoaded){
                let res = await axios.get(`${api_url}/services`).catch(()=> {});
                if(res){
                let services = res.data;
                ctx.commit('setServices',services);
                }
            }
        },
    },
}
