import { i18n } from '../lang';

export default {
  state: {
    language:'en',
  },
  getters: {
    language: state => state.language,
  },
  mutations: {
    setLanguage: (state, language) => state.language=language,
  },
  actions: {
        setLanguage:({commit},language) => {
            localStorage.setItem("language",language);
            i18n.setLocale(language);
            document.documentElement.setAttribute('lang', language);
            commit('setLanguage',language);
        },
    },
}
