<template>
    <section class="bg-light">
        <div class="container">
            <h4>{{$t('OUR SERVICES')}}</h4>
            <h2>{{$t('What we do')}}</h2>
            <div class="space-30" style="height: 30px"></div>
            <div class="row">
                <ServiceBox v-for="service in services" :key="service.id" :title="service.title" :image="service.image" :id='service.id'/>               
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex';
import ServiceBox  from './ServiceBox.vue';
export default {
  name: "MyServices",
  components: { ServiceBox },
  data() {
      return {
        //   services:['aa','bb','cc','dd']
      }
  },
    computed: {
        ...mapState({
            language: state => state.language.language,
            services: state => state.services.services,
        }),
    }
};
</script>

<style scoped></style>
