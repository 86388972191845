import { createI18n } from 'vue3-i18n'
import en from './translations/en.json'
import ar from './translations/ar.json'
import ku from './translations/ku.json'

const messages = {
    en: en,
    ar: ar,
    ku:en
};

export const i18n = createI18n({
    locale: 'ar',
    messages: messages,
  
});
