window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';

window.Vue = require('vue');

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {store} from './store';
import {i18n} from './lang';


let language = localStorage.getItem("language")??'en';
if(!['en','ar','ku'].includes(language)) language='en';
store.dispatch('setLanguage',language);
window.axios.defaults.headers.common['Accept-Language'] = language;


const app = createApp(App).use(router).use(store).use(store).use(i18n);
app.mount('#app');
