<template>
    <div id="content" class="site-content">
        <div class="page-404" style="background: url('/images/error.jpg') no-repeat center center;">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="error-title text-white">4<i class="icon ion-ios-outlet"></i>4 Error</h1>
                        <hr>
                        <h3 class="error-subtitle text-white">Opps...! sorry we can’t find this page!</h3>
                        <div class="industris-space"></div>
                        <router-link :to='"/"' class="btn btn-primary">Back to home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted(){
       loadPage();
    },
}
</script>

<style scoped>

</style>
