<template>
    <section>
        <div class="container">
            <div class="row">
                <ServiceList :service="service"/>
                <div class="col-md-8 col-sm-12">
                    <h4 class="text-primary">OVERVIEW</h4>
                    <h2>{{service?.title}}</h2>
                    <p>{{service?.description}}</p>                
                    <img :src="storage__url + '/' + service?.content_image" alt="">
                    <div class="industris-space"></div> 
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import { mapState } from 'vuex';
import ServiceList from './ServiceList.vue';
import { storage_url } from '../constants';

export default {
    name: "ServiceDetails",
    components:{ServiceList},
    props: {
     'service': String,
    },
    data() {
        return {
            storage__url:storage_url
        }
    },
    computed: {
        ...mapState({
            language: state => state.language.language,
            services: state => state.services.services,
        }),
    }
};
</script>

<style scoped></style>