import Home from '../views/Home.vue';
import Contact from '../views/Contact.vue';
import Services from '../views/Services.vue';
import Service from '../views/Service.vue';
import NotFound from '../views/NotFound.vue';

export const routes = [
    {path: '/', component: Home},
    {path: '/contact', component: Contact},
    {path: '/services', component: Services},
    {path: '/services/:id', component: Service},
    {path: '/services/:id', name:'service' , component: Service},
    {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

]