<template>
    <MyBanner/>
    <About/>
    <ServicesBrief/>
    <Reviews/>
</template>

<script>
import MyBanner from '../components/MainBanner.vue';
import About from '../components/About.vue';
import ServicesBrief from '../components/ServicesBrief.vue';
import Reviews from '../components/Reviews.vue';

export default {
    name: 'MyHome',
    components: { MyBanner , About , ServicesBrief , Reviews}, 
    async mounted(){
        await this.$store.dispatch('fetchServices');
        await this.$store.dispatch('fetchReviews');
        loadPage();
    },
}
</script>

<style>

</style>
