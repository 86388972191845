<template>
<Loader/>
 <MyNavbar/>
  <router-view/>
  <MyFooter/>
</template>
<script>
import Loader from './components/Loader.vue'
import MyNavbar from './components/Navbar.vue'
import MyFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: { Loader , MyNavbar , MyFooter},
   async mounted(){
        
        loadPage();
    }
}
</script>
<style>
</style>
