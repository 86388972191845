<template>
    <SecondaryBanner :title='title' :paths='paths' :background='background'/>
    <ContactInfo/>
    <MyMap/>
</template>

<script>
import SecondaryBanner from '../components/SecondaryBanner.vue';
import ContactInfo from '../components/ContactInfo.vue';
import MyMap from '../components/Map.vue';

export default {
    name: 'Contact',
    components: { SecondaryBanner , ContactInfo , MyMap}, 
    data() {
        return {
            title:"Contact Us",
            paths:[
                {title:'Home',url:'/'}
            ],
            background:"/images/contact2.jpg"
        };
   },
   async mounted() { 
    await this.$store.dispatch('fetchServices');
    loadPage()
   },
   
}
</script>

<style>

</style>
